<template>
  <div class="success-animation mb-5">
    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
      <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
    </svg>
  </div>
  <p class="text-green-vogue-700 ">Rendez-vous :
    <span class="capitalize font-semibold text-primary">
          {{ dayjs(availability.start).locale('fr').format('dddd DD MMMM - HH:mm') }}</span>
  </p>
  <p class="text-green-vogue-700">Agence :
    <span class="font-semibold text-primary">{{ shop.name }}</span>
  </p>
  <p class="font-semibold text-primary">{{shop.address }}, {{ shop.zipcode }} {{ shop.city }}</p>
  <p class=" text-green-vogue-700">Durée du rendez-vous : <span class=" font-semibold">{{
      dayjs(availability.end).diff(availability.start,
          'minute')
    }} minutes</span></p>

<!--  <div class="flex flex-col sm:flex-row gap-2 justify-between mt-6">-->
<!--    <UButton :to="`/nos-agences/${shop.slug}`" @click="modal.close()">En savoir plus-->
<!--      sur l'agence-->
<!--    </UButton>-->
<!--    <UButton-->
<!--        :to="`https://www.google.com/maps/dir//${shop.address}`"-->
<!--        target="_blank" variant="outline">-->
<!--      Se rendre à l'agence-->
<!--      <template #trailing>-->
<!--        <UIcon name="i-bi-sign-turn-right-fill" dynamic class=" size-4"/>-->
<!--      </template>-->
<!--    </UButton>-->
<!--  </div>-->
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import dayjs from "dayjs";

export default defineComponent({
  name: "AppointmentSuccess",
  props: ['shop', 'availability'],
  methods: {dayjs}
})
</script>

<style scoped>
.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}
</style>
