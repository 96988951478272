<script setup lang="ts">
import type { DatePickerDate, DatePickerRangeObject } from 'v-calendar/dist/types/src/use/datePicker'
import {DatePicker as VCalendarDatePicker } from 'v-calendar'
import 'v-calendar/dist/style.css'


const props = defineProps({
  allDates: {
    type: Array,
    required: true
  },
  modelValue: {
    type: [Date, Object] as PropType<DatePickerDate | DatePickerRangeObject | null>,
    default: null
  }
})

const emit = defineEmits(['update:model-value', 'close'])

const date = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:model-value', value)
    emit('close')
  }
})

const attrs = {
  transparent: false,
  borderless: false,
  color: 'primary',
  'is-dark': {
    selector: 'html',
    darkClass: 'dark'
  },
  'first-day-of-week': 2,
}
const attributes = ref([{}])



const disabledDates = computed(() => {
  const allDates = props.allDates.map(date => new Date(date));
  const lastDate = allDates.at(-1);

  const disabledDates = [];

  const today = new Date();
  for (let date = new Date(today); date <= lastDate; date.setDate(date.getDate() + 1)) {
    const currentDate = new Date(date);
    if (!allDates.some(formattedDate => formattedDate.toDateString() === currentDate.toDateString())) {
      disabledDates.push(currentDate);
    }
  }

  return disabledDates;
});
</script>

<template>
  <VCalendarDatePicker v-model="date" v-bind="{ ...attrs, ...$attrs }" expanded :attributes="attributes"
    :disabled-dates="disabledDates" :min-date="allDates[0]" :max-date="allDates.at(-1)" />
</template>

<style>
:root {
  --vc-gray-50: rgb(var(--color-gray-50));
  --vc-gray-100: rgb(var(--color-gray-100));
  --vc-gray-200: rgb(var(--color-gray-200));
  --vc-gray-300: rgb(var(--color-gray-300));
  --vc-gray-400: rgb(var(--color-gray-400));
  --vc-gray-500: rgb(var(--color-gray-500));
  --vc-gray-600: rgb(var(--color-gray-600));
  --vc-gray-700: rgb(var(--color-gray-700));
  --vc-gray-800: rgb(var(--color-gray-800));
  --vc-gray-900: rgb(var(--color-gray-900));
}

.vc-primary {
  --vc-accent-50: rgb(var(--color-primary-50));
  --vc-accent-100: rgb(var(--color-primary-100));
  --vc-accent-200: rgb(var(--color-primary-200));
  --vc-accent-300: rgb(var(--color-primary-300));
  --vc-accent-400: rgb(var(--color-primary-400));
  --vc-accent-500: rgb(var(--color-primary-500));
  --vc-accent-600: rgb(var(--color-primary-600));
  --vc-accent-700: rgb(var(--color-primary-700));
  --vc-accent-800: rgb(var(--color-primary-800));
  --vc-accent-900: rgb(var(--color-primary-900));
}
</style>